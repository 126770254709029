/**
 * @flow
 */
import { logBreadcrumbToSentry } from "../../shared/utils/sentryUtils";

type JargonType = { [key: string]: string };

/* eslint-disable i18next/no-literal-string */

export function jargonize(message: string, jargon?: JargonType): string {
  const jargonRegex = /#\[JARGON:([^\]]+)]/g;

  if (!jargon) {
    // This shouldn't happen in a development environment -- if it does, let's blow up.
    if (process.env.NODE_ENV === "development") {
      throw new Error("Jargon dictionary not provided in development environment.");
    }

    // This shouldn't happen. If it does, e.g. due to an OS error, let's log it and provide something legible.
    logBreadcrumbToSentry({
      message: "Jargon dictionary not provided",
      functionName: "jargonize",
      inputMessage: message,
    });
    return message.replace(jargonRegex, (_, key) => key.replace(/_/g, " "));
  }

  return message.replace(jargonRegex, (fullMatch, key) => {
    const normalizedKey = key.replace(/ /g, "_");
    return normalizedKey in jargon ? jargon[normalizedKey] : fullMatch;
  });
}
