/**
 * @flow
 */

/* eslint-disable i18next/no-literal-string */

import { fetchWithNoExpectedResponseData } from "../../shared/fetchWithAuth";
import type { ResponseError, ResponseNoDataSuccess } from "../../shared/fetchWithAuth";
import { jargonize } from "./jargon";

export const _DEFAULT_LANGUAGE_KEY: string = "default";

// This is the one place we call Django's actual interpolate method. Everything else should use 'namedInterpolate'.
// eslint-disable-next-line no-restricted-syntax
window.namedInterpolate = (fmt: string, object: { ... }): string => window.interpolate(fmt, object, true);

window.getLanguageCode = (): string => {
  const languageCode = document.querySelector("html")?.getAttribute("lang") || "en";
  if (languageCode === "en-loc") {
    // The ancient egyption language code we use for testing translations has no specific weekdays/month translations,
    // so we use Spanish. With this we can visually verify calendar and date localization on the site.
    return "es-us";
  }
  try {
    // Validate the language code. If it's invalid it will cause crashes.
    // $FlowExpectedError - Flow doesn't seem to have valid type data for this function.
    const validLocale = Intl.getCanonicalLocales(languageCode)?.[0];
    // Since Flow doesn't know what type we have, and techincally we could have undefined, do a typeof check.
    if (typeof validLocale === "string") {
      return validLocale;
    }
  } catch {
    // Default to English if we have a bad language code.
    return "en";
  }
  return "en";
};

/*
 * We are overriding the window.gettext methods with versions that will take the output
 * and replace any remaining jargon in the translated message based on the current active language.
 *
 * Functions that we override include:
 *   window.gettext
 *   window.ngettext
 *   window.gettext_noop
 *   window.pgettext
 *   window.npgettext
 * */
/* eslint-disable func-names */
/* eslint-disable no-undef */
window.gettext = function (msgid: string): string {
  return jargonize(django.gettext(msgid), window.jargon[window.getLanguageCode().toLowerCase()]);
};
window.gettext_noop = function (msgid: string): string {
  return jargonize(django.gettext_noop(msgid), window.jargon[window.getLanguageCode().toLowerCase()]);
};
window.pgettext = function (context: string, msgid: string): string {
  return jargonize(django.pgettext(context, msgid), window.jargon[window.getLanguageCode().toLowerCase()]);
};
window.ngettext = function (singular: string, plural: string, count: number): string {
  return jargonize(django.ngettext(singular, plural, count), window.jargon[window.getLanguageCode().toLowerCase()]);
};
window.npgettext = function (context: string, singular: string, plural: string, count: number): string {
  return jargonize(
    django.npgettext(context, singular, plural, count),
    window.jargon[window.getLanguageCode().toLowerCase()]
  );
};
/* eslint-enable no-undef */
/* eslint-enable func-names */

export function requestIgnoreNoPreferredSiteLanguageWarning(): Promise<ResponseNoDataSuccess | ResponseError> {
  return fetchWithNoExpectedResponseData(
    `${window.location.origin}/localize/ignore-no-preferred-site-language-warning`,
    { method: "post" }
  );
}

export function selectedLanguageCodeURL(selectedLanguageCode: string): string {
  return `/localize/${selectedLanguageCode}`;
}

const _fuzzySelectLanguageKey = (languageDict: { [string]: string }, preferredLanguage: string): string => {
  if (languageDict[preferredLanguage] !== undefined) {
    return preferredLanguage;
  }

  const baseLanguage = preferredLanguage.split("-")[0];
  for (const languageKey in languageDict) {
    if (languageKey.split("-")[0] === baseLanguage) {
      return languageKey;
    }
  }

  return _DEFAULT_LANGUAGE_KEY; // Fallback to the default language key
};

export function getDisplayText(languageDict: ?{ [string]: string }, forceLanguageCode: ?string): string {
  if (!languageDict) {
    return "";
  }
  const languageCode = forceLanguageCode ?? window.getLanguageCode();
  const textKey = _fuzzySelectLanguageKey(languageDict, languageCode);
  return languageDict[textKey] || languageDict[_DEFAULT_LANGUAGE_KEY];
}
